import Logger from 'utils/logging'
import { isClient } from 'config/web'
import emitDownloadEvent from 'services/api/event/emit-download-event'
import {
    PlatformTypes,
    JourneyValuesType,
    UtmType,
    ClickIdType,
    PageInfoType,
    InstallerDownloadMessage,
} from './models'
import getUserId from './helpers/user-id'
import { getContext } from './mapping/context'
import JourneyValuesManager from './helpers/journey-values'
import JourneyIdManager from './helpers/journey-id'

export async function publishInstallerDownloadEvent(
    platform: PlatformTypes,
    operatingSystem: string
): Promise<void> {
    try {
        // Settings has to come in and have correct values to continue, enabled and prefereably valid gateway url
        const journeyValuesManager = new JourneyValuesManager(isClient)
        const journeyValues = journeyValuesManager.getJourneyValues() as JourneyValuesType
        const userId = getUserId()
        const manager = new JourneyIdManager()
        const journeyId = manager.getJourneyId()

        const context = getContext(
            journeyValues as UtmType,
            journeyValues as ClickIdType,
            journeyValues as PageInfoType
        )

        // Construct the message
        const message: InstallerDownloadMessage = {
            context,
            platform,
            operatingSystem,
            userId,
            journeyId,
        }

        const response = await emitDownloadEvent(message)

        if (!response) {
            throw new Error(
                'Failed to emit download event, response was empty or false'
            )
        }
    } catch (error) {
        Logger.captureException(error, null, {
            category: 'proto',
            functionName: 'publishInstallerDownloadEvent',
        })
    }
}
