import React, { useEffect, useState } from 'react'
import {
    asyncWithLDProvider,
    LDMultiKindContext,
} from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { isLoggedIn } from 'packages/authentication'
import getConfig, { isClient } from 'config/web'
import JwtTokenHelper from 'lib/jwt/JwtTokenHelper'
import { getToken } from 'packages/authentication/helpers/token'
import JourneyIdManager from 'events/download-event/helpers/journey-id'
import { GlobalState } from 'types'
import { Loading } from 'layouts'

interface LDUserContext {
    kind: string
    anonymous?: boolean
    key?: string
    custom?: LDCustomAttributes
}

interface LDCustomAttributes extends Record<string, unknown> {
    userIds: number[]
    language: string
}

let isError = false

function setError(value: boolean): void {
    isError = value
}

export function LaunchDarklyProvider({
    children,
}: {
    children: React.ReactNode
}): JSX.Element | null {
    const [
        LDProvider,
        setLDProvider,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] = useState<React.ComponentType<any> | null>(null)

    const loggedIn = useSelector((state: GlobalState) => isLoggedIn(state))
    const language = useSelector((state: GlobalState) => state.language)
    const { launchDarklyClientId } = getConfig()

    const jidManager = new JourneyIdManager()

    const createUserContext = (jwtToken?: string): LDUserContext => {
        const jid = jidManager.getJourneyId()

        const ctx: LDMultiKindContext = {
            kind: 'multi',

            journey: {
                key: jid,
                anonymous: true,
            },
        }
        if (jwtToken) {
            const userId = JwtTokenHelper.getUserId(jwtToken)
            ctx.user = {
                key: `${userId}`,
                language: language || 'en',
            }
        } else {
            ctx.guest = {
                key: jid,
                language: language || 'en',
            }
        }
        return ctx
    }

    useEffect(() => {
        const initLD = async (): Promise<void> => {
          try {
            const ctx = createUserContext(
                isClient && loggedIn ? getToken() : undefined
            )

            const Provider = await asyncWithLDProvider({
                clientSideID: launchDarklyClientId,
                context: ctx,
            })

            setLDProvider(() => Provider)
          } catch (error) {
            console.warn('Error initializing LaunchDarklyProvider:', error)
            Error('Error initializing LaunchDarklyProvider:', error)
            setError(true)
          }
        }

        initLD()
    }, [loggedIn])

    // If there was an error initializing LDProvider, render page without LDProvider.
    if(isError) {
      return children as JSX.Element
    }

    // If LDProvider hasn't been initialized yet, don't render anything.
    if (!LDProvider) {
        // console.log('LDProvider not initialized yet')
        return <Loading />
    }

    return <LDProvider>{children}</LDProvider>
}
