import decode from 'jwt-decode'
import parseInt from 'lodash/parseInt'
import getConfig from 'config/web'
import { te } from 'date-fns/locale'
import { setItem, removeItem, getItem } from '../../../utils/storage'

const {
    authentication: { scopesArray, clientId },
} = getConfig()

const TOKEN_KEY = 'token'
const ID_TOKEN_KEY = 'id-token'
const EXPIRES_AT_KEY = 'expiresAt'
const AZP_KEY = 'ccp-azp'
export const LOGINSTATE = 'loginState'
export const VERIFIER_KEY = 'codeVerifier'
export const REFRESH_TOKEN = 'ccp-rt'
export const PREVIOUS_PATH_COOKIE = 'www_prev_path'
const REFRESH_EXPIRATION_DAYS = 7 // Expire refresh cookie in 7 days

export const getExpiresAt = () => Number(getItem(EXPIRES_AT_KEY)) || null

export const getToken = () => {
    const expiresAt = getExpiresAt()

    if (expiresAt === null || expiresAt < Date.now()) {
        // login(window.location.href)
    }

    if (expiresAt === null || expiresAt > Date.now()) {
        return getItem(TOKEN_KEY) || null
    }
    return null
}

export const getIdToken = () => {
    const expiresAt = getExpiresAt()

    if (expiresAt === null || expiresAt > Date.now()) {
        return getItem(ID_TOKEN_KEY) || null
    }
    return null
}

export const getSecondsUntilTokenExpires = () => {
    const expiresAt = getExpiresAt()
    const diff = expiresAt - Date.now()
    return diff / 1000
}

export const ShouldFetchNewTokenIfNeeded = () => {
    const diffSeconds = getSecondsUntilTokenExpires()
    if (diffSeconds < 300) {
        return true
    }
    return false
}

export const convertExpiresSecondsToTime = (expiresIn) => {
    const expiresDate = expiresIn ? parseInt(expiresIn) : NaN
    return !isNaN(expiresDate) ? Date.now() + expiresDate * 1000 : null
}

export const getUserFromJwt = () => {
    const currToken = getToken()
    let decodedToken = ''
    if (currToken) {
        decodedToken = decode(currToken)
    } else {
        decodedToken = ''
    }

    // Here we get the display name from identity token if it exists
    let username = decodedToken.name

    // New Identity Token usage
    // Only use id token values if it's there, else use from access token like before
    const idToken = getIdToken()

    if (idToken) {
        const decodedIdToken = decode(idToken)
        if (decodedIdToken) {
            if (decodedIdToken.name) {
                username = decodedIdToken.name
            } else if (decodedIdToken.display) {
                username = decodedIdToken.display
            }
        }
    }

    return username
}

export const getAzp = () => {
    return getItem(AZP_KEY) || azpFromJwt(getToken()) || clientId
}

export const validateScopes = (scopes) => {
    return scopesArray.every((scope) => scopes.includes(scope))
}

export const getScopesFromJwt = (token = null) => {
    const currToken = token || getToken()
    let decodedToken = ''
    if (currToken) {
        decodedToken = decode(currToken)
    } else {
        decodedToken = ''
    }
    return decodedToken.scp
}

export const azpFromJwt = (token = null) => {
    const currToken = token || getToken()
    let decodedToken = ''
    if (currToken) {
        decodedToken = decode(currToken)
    } else {
        decodedToken = ''
    }
    return decodedToken.azp
}

export const SetUniqueState = (uniqueState) => {
    setItem(LOGINSTATE, uniqueState)
}

export const hasToken = () => getToken() !== null

export const setToken = (
    token,
    expiresAt,
    refreshToken = null,
    idToken = null
) => {
    setItem(TOKEN_KEY, token)
    setItem(AZP_KEY, azpFromJwt(token))
    if (expiresAt !== null) {
        setItem(EXPIRES_AT_KEY, expiresAt)
    } else {
        removeItem(EXPIRES_AT_KEY)
    }
    if (refreshToken !== null) {
        setRefreshToken(refreshToken)
    }
    if (idToken !== null) {
        setItem(ID_TOKEN_KEY, idToken)
    }
}

export const setRefreshToken = (token) => {
    const expires = new Date()
    expires.setDate(expires.getDate() + REFRESH_EXPIRATION_DAYS)
    setItem(REFRESH_TOKEN, token, { path: '/', sameSite: 'strict', expires })
}

export const hasRefreshToken = () => getItem(REFRESH_TOKEN) != null

export const getRefreshToken = () => getItem(REFRESH_TOKEN)

export const removeToken = () =>
    new Promise((resolve, reject) => {
        removeItem(TOKEN_KEY)
        removeItem(EXPIRES_AT_KEY)
        removeItem(LOGINSTATE)
        removeItem(REFRESH_TOKEN)
        removeItem(ID_TOKEN_KEY)
        resolve()
    })
