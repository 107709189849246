import Logger from 'utils/logging'
import { InstallerDownloadMessage } from 'events/download-event/models'
import client from '../client'

export default async function emitDownloadEvent(
    message: InstallerDownloadMessage
): Promise<boolean> {
    try {
        const response = await client({
            url: '/events/download',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            method: 'post',
            data: message,
        })

        return response.data
    } catch (error) {
        Logger.captureNetworkException(
            error,
            'events/download',
            'api.events.download'
        )
        throw new Error('Failed to emit download event')
    }
}
