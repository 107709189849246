import React, { useEffect, useState } from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import {
    LocalizedElement,
    LocalizeProvider,
    onMissingTranslationFunction,
} from 'utils/localize'
import { useHasMounted } from 'hooks/client-only'
import { HelmetProvider } from 'react-helmet-async'
import { SettingsProvider } from 'settings'
import getApolloContentfulClient from 'utils/apollo/client'
import { renderToStaticMarkup } from 'react-dom/server'
import { getLanguages } from 'selectors'
import { redirectFromLangCookie } from 'utils/langCookie'
import JourneyValuesManager from 'events/download-event/helpers/journey-values/journey-values-manager'
import sessionId from 'utils/session-id'
import TagManager from 'react-gtm-module'
import getConfig, { isClient } from 'config/web'
import ScrollContext from 'utils/context/ScrollContext'
import reportWebVitals from 'utils/analytics/webVitals/reportWebVitals'
import { UserProvider } from 'utils/context/UserContext'
import { LaunchDarklyProvider } from 'utils/launchDarkly/LaunchDarklyProvider'

import {
    hasToken,
    hasRefreshToken,
    getToken,
    getExpiresAt,
    getIdToken,
} from './packages/authentication/helpers/token'
import {
    loginSuccess,
    authDoRefreshToken,
} from './packages/authentication/lib/actions'
import App from './layouts/app'
import './index.scss'
// import * as serviceWorker from './serviceWorker'
import configureStore from './configureStore'
import './config/fontawesome'
import globalTranslations from './globalTranslations'

// We should look into removing this, it is huge and might be loaded for all and things are much better supported now in most browsers
import 'airbnb-browser-shims'

const { gtmId } = getConfig()

const { store } = configureStore(window.REDUX_STATE)

interface LocationState {
    payload: unknown
    query: unknown
    pathname: string
}

function processClientInitialLoad(): void {
    // Generate session ID
    sessionId()
    // Check if the user has auth cookie on client-side only
    if (hasToken()) {
        store.dispatch(
            loginSuccess(getToken(), getExpiresAt(), null, getIdToken())
        )
    } else if (hasRefreshToken()) {
        store.dispatch(authDoRefreshToken())
    }

    const state = store.getState()

    const location = state?.location as LocationState
    // const { location } = state

    const { payload, query, pathname } = location

    // const payload = location.payload
    // const query = location.query
    // const path = location.pathname

    // PROTO_EVENT_TRACKING_CODE
    if (isClient) {
        const journeyValuesManager = new JourneyValuesManager(isClient)
        journeyValuesManager.processFirstPageVisit(pathname, query)

        // Add web vitals here
        reportWebVitals()
    }

    // redirecting to the language saved in the cookie if required
    redirectFromLangCookie(store.dispatch, payload, location)
}

const tagManagerArgs = {
    gtmId,
}

TagManager.initialize(tagManagerArgs)

const client = getApolloContentfulClient()

const onMissingTranslation: onMissingTranslationFunction = ({
    defaultTranslation,
}): LocalizedElement => defaultTranslation

const language = (store.getState().language as string) || 'en'

const HTML = (): JSX.Element => {
    const hasMounted = useHasMounted()

    if (hasMounted) {
        processClientInitialLoad()
    }

    return (
        <Provider store={store}>
            <SettingsProvider>
                <UserProvider>
                    <ApolloProvider client={client}>
                        <LocalizeProvider
                            store={store}
                            initialize={{
                                languages: getLanguages(),
                                translation: globalTranslations,
                                options: {
                                    defaultLanguage: language,
                                    renderToStaticMarkup,
                                    onMissingTranslation,
                                },
                            }}
                        >
                            <HelmetProvider>
                                <ScrollContext>
                                    <LaunchDarklyProvider>
                                        <App />
                                    </LaunchDarklyProvider>
                                </ScrollContext>
                            </HelmetProvider>
                        </LocalizeProvider>
                    </ApolloProvider>
                </UserProvider>
            </SettingsProvider>
        </Provider>
    )
}

const container = document.getElementById('root')

if (module.hot) {
    const root = createRoot(container)
    root.render(<HTML />)
} else {
    hydrateRoot(container, <HTML />)
}

// serviceWorker.unregister()
