import React, { useEffect, useState } from 'react'
import { useLauncherConnection } from 'hooks/launcher'
import JourneyIdManager from 'events/download-event/helpers/journey-id'
import { useLDClient } from 'launchdarkly-react-client-sdk'

export const LauncherDownloadHandler = (): JSX.Element => {
    const journeyIdManager = new JourneyIdManager()
    const ldClient = useLDClient()
    const [isLDReady, setIsLDReady] = useState(false)

    // Initialize contextKey with an empty string or null
    const [contextKey, setContextKey] = useState<string | null>(null)

    // Hook to wait for ldClient initialization
    useEffect(() => {
        let isMounted = true

        if (ldClient) {
            ldClient
                .waitForInitialization(5)
                .then(() => {
                    if (isMounted) {
                        setIsLDReady(true)
                        const context = ldClient.getContext()
                        if (context && context.key) {
                            setContextKey(context.key.toString())
                        } else {
                            console.error('Context or context key is undefined')
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error initializing ldClient:', error)
                })
        }

        return () => {
            isMounted = false
        }
    }, [ldClient])

    // Prepare parameters with initial values
    const parameters: Record<string, string> = {
        journeyId: journeyIdManager.getJourneyId(),
        contextKey: contextKey || '',
    }
    const path = '/journey'

    // console.log('DownloadHandler parameters:', parameters)

    // Call useLauncherConnection unconditionally
    const { success, failed, openProtocolHandler } = useLauncherConnection({
        automatic: true,
        path,
        parameters,
        automaticAttempts: 60,
        automaticInterval: 1000,
        keepTrying: true,
    })

    useEffect(() => {
        if (success) {
            console.log('The launcher has been summoned')
        }
    }, [success])

    useEffect(() => {
        if (failed) {
            console.log(
                'The launcher refuses to listen to our hails, we must use different means of summoning'
            )
            openProtocolHandler()
        }
    }, [failed, openProtocolHandler])

    // Conditional rendering happens after hooks
    if (!ldClient || !isLDReady || !contextKey) {
        console.log('Loading...')
        return <div>Loading...</div>
    }

    return null
}
