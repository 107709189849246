import React from 'react'
import { Container, Section, SvgIcon } from 'layouts'
import Link from 'features/link'
import { TaglineLarge } from 'layouts/typography'
import BackgroundImage from 'features/background-image'
import { LauncherDownloadHandler } from 'features/download/launcher-download-handler'
import { AutoDownload } from './components'

import style from './Install.module.scss'

const Install = (): JSX.Element => {
    const ThreeDotsLoading: React.FC = () => {
        return (
            <div className={style.dots}>
                <div className={`${style.dot} ${style.dot1}`} />
                <div className={`${style.dot} ${style.dot2}`} />
                <div className={`${style.dot} ${style.dot3}`} />
            </div>
        )
    }

    return (
        <BackgroundImage
            url="//images.ctfassets.net/7lhcm73ukv5p/7gAkqvYwrcM76hjpNU19yg/5785ccd97fa1fa55bcebb31380c0448b/install-bg.jpg"
            // repeat="no-repeat"
            size="cover"
            position="top center"
        >
            <div className={style.main}>
                <Link className={style.logo} title="Home">
                    <SvgIcon
                        width={130}
                        name="eve-logo-with-text"
                        fill="#fff"
                    />
                </Link>
                {/* Run Auto Download, comment out to not do that all the time */}
                <AutoDownload />
                <Container>
                    <Section hasContent className={style.content}>
                        <TaglineLarge
                            fontSize={[28, 48]}
                            fontWeight={600}
                            className={style.title}
                        >
                            Downloading the{' '}
                            <span>
                                EVE Online Launcher <ThreeDotsLoading />
                            </span>
                        </TaglineLarge>
                        <div className={style.text}>
                            <p>
                                Please keep this window open until the
                                installation is complete.
                            </p>
                            <p className={style.secondary}>
                                If your download hasn&rsquo;t started
                                automatically,{' '}
                                <a href="/download">click here</a>.
                            </p>
                        </div>
                        {/* Is this needed here, this might be only if user signs up on web and has access token to the launcher */}
                        <LauncherDownloadHandler />
                    </Section>
                </Container>
            </div>
        </BackgroundImage>
    )
}

export default Install
