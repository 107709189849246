import React from 'react'
import { Section, Container } from 'layouts'
import SectionType from 'models/types/ts/sectionType'
import { useHasMounted } from 'hooks/client-only'
import { HeadingSmall } from 'layouts/typography'
import { LauncherDownloadHandler } from 'features/download/launcher-download-handler'
import DownloadButton from 'features/DownloadButton'
import SystemRequirements from './SysReq'
import style from './Download.module.scss'

interface Props {
    title: string
    body: string
    sections: Array<SectionType>
    hasContent: boolean
}

const Download = ({
    title,
    body,
    sections,
    hasContent,
}: Props): JSX.Element => {
    const hasMounted = useHasMounted()

    return (
        <div className={style.download}>
            <Section hasContent={hasContent}>
                <Container>
                    <div style={{ textAlign: 'center' }}>
                        <HeadingSmall>{title}</HeadingSmall>

                        <div
                            style={{
                                maxWidth: '400px',
                                marginTop: '40px',
                                textAlign: 'center',
                                margin: '0 auto',
                            }}
                        >
                            {hasMounted && <DownloadButton />}
                        </div>
                    </div>
                    <SystemRequirements sections={sections} />
                    <LauncherDownloadHandler />
                </Container>
            </Section>
        </div>
    )
}

export default Download
