import { Context, ClickIdType, UtmType, PageInfoType } from '../../models'

export function setUtmValues(utmValues: UtmType): UtmType | undefined {
  if (utmValues) {
    const utm: UtmType = {}
    const {
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_term: utmTerm,
      utm_content: utmContent,
    } = utmValues

    if (utmSource) utm.utm_source = utmSource
    if (utmMedium) utm.utm_medium = utmMedium
    if (utmCampaign) utm.utm_campaign = utmCampaign
    if (utmTerm) utm.utm_term = utmTerm
    if (utmContent) utm.utm_content = utmContent

    return utm
  }
}

// Set click id logic:
// Check if any are set and them set them
// only one click id is needed and if more than one should come in
// then this is the order priority google > yandex > facebook
export function setClickIdValues(clickIdValues: ClickIdType): ClickIdType | undefined {
  if (clickIdValues) {
    const clickId: ClickIdType = {}
    const { gclid, yclid, fbclid } = clickIdValues

    if (gclid) clickId.gclid = gclid
    if (yclid) clickId.yclid = yclid
    if (fbclid) clickId.fbclid = fbclid

    return clickId
  }
}

// Set page info values
export function setPageInfoValues(pageInfoValues: PageInfoType): PageInfoType | undefined {
  if (pageInfoValues) {
    const pageInfo: PageInfoType = {}
    const { gaClientId, locale, landingPage, referrerDomain } = pageInfoValues

    if (gaClientId) pageInfo.gaClientId = gaClientId
    if (locale) pageInfo.locale = locale
    if (landingPage) pageInfo.landingPage = landingPage
    if (referrerDomain) pageInfo.referrerDomain = referrerDomain

    return pageInfo
  }
}

export function getContext(
    utmValues: UtmType,
    clickIdValues: ClickIdType,
    pageInfoValues: PageInfoType
): Context {

    const context: Context = {
        utm: {},
        clickId: {},
        pageInfo: {},
    }

    context.utm = setUtmValues(utmValues)
    context.clickId = setClickIdValues(clickIdValues)
    context.pageInfo = setPageInfoValues(pageInfoValues)

    return context
}
