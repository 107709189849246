import { isClient } from 'config/web'
import JourneyIdManager from 'events/download-event/helpers/journey-id'
import { PlatformTypes } from 'events/download-event/models'
import { publishInstallerDownloadEvent } from 'events/download-event/publish-download-event'
import Platform from 'platform'
import { pushClientDownloadEventToDataLayer } from 'utils/analytics/datalayer/dataLayerFunctions'
import { DataLayerEventActionType } from 'utils/analytics/datalayer/models/DataLayerEventTypes'

function dataLayerPush(platform, filename): void {
    if (isClient) {
        let eventAction: DataLayerEventActionType
        let eventLabel: string

        if (platform === 'windows') {
            eventAction = 'Windows'
            eventLabel = 'EVE client Windows download'
        } else {
            eventAction = 'Mac OS'
            eventLabel = 'EVE client Mac download'
        }

        // Append filename to event label
        eventLabel = `${eventLabel} - ${filename}`

        pushClientDownloadEventToDataLayer(eventAction, eventLabel)
    }
}

export default function sendDownloadEvents(
    platform: unknown,
    filename: string
): void {
    const jidManager = new JourneyIdManager()
    jidManager.getJourneyId()

    // Send gRPC download event
    publishInstallerDownloadEvent(platform as PlatformTypes, Platform.os.family)

    // Send dataLayer download event
    dataLayerPush(platform, filename)
}
