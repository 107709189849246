import React, { useEffect } from 'react'

import Platform from 'platform'

import getConfig, { isClient } from 'config/web'
import sendDownloadEvents from 'utils/download'

export const AutoDownload = (): JSX.Element => {
    console.log('Auto Download page loading!')

    // TODO add to helper, and use that here and in DownloadButton
    const { launcherWindowsBaseUrl, launcherMacBaseUrl } = getConfig()
    const family = Platform.os.family
    const isWin = family.includes('Windows')
    const winFilename = 'eve-online-latest+Setup.exe'
    const macFilename = 'eve-online-latest-universal.dmg'

    // TODO not download if on mobile, or not on win or mac
    // Maybe that check should be elsewhere and then this component should not have that responsibility

    let path = ''
    let filename = ''
    if (isWin) {
        path = launcherWindowsBaseUrl
        filename = winFilename
    } else {
        path = launcherMacBaseUrl
        filename = macFilename
    }

    const downloadFile = path + filename

    const download = (): void => {
        setTimeout(() => {
            const response = {
                downloadFile,
            }
            window.location.href = response.downloadFile
        }, 3000) // Start download after 3 seconds
    }

    const platform = isWin ? 'windows' : 'apple'

    useEffect(() => {
        if (isClient) {
            download()
            sendDownloadEvents(platform, filename)
        }
    }, [])

    return <></>
}
