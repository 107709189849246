import React from 'react'
import { Container, Visibility } from 'layouts'
import SectionType from 'models/types/ts/sectionType'
import { HeadingMedium, TaglineRegular } from 'layouts/typography'
import BackgroundImage from 'features/background-image'
import AnimatedText from 'layouts/animated-text/AnimatedText2'
import Sale from 'features/navigation/components/sale'
import { useIsMobile } from 'hooks/device-detect'
import { LaunchDarklyProvider } from 'utils/launchDarkly/LaunchDarklyProvider'
import CTA from './components/cta'
import Platforms from './components/platforms'
import Quotes from '../quotes'
import style from './Hero.module.scss'
import Announcement from './components/announcement'

interface Props {
    section: SectionType
    quotes: SectionType
}

const Hero = ({ section, quotes }: Props): JSX.Element => {
    const isMobile = useIsMobile()

    const {
        headline,
        teaser,
        imageFile,
        template = '', // alignment
        videoId,
        image,
        contentCollection: { items },
    } = section

    // Mobile image could be improved with sizing for small mobile

    const renderContent = (): JSX.Element => (
        <BackgroundImage
            url={imageFile ? imageFile.url : ''}
            repeat="no-repeat"
            size="cover"
            height={800}
            position="center top"
            className={style.hero}
            lazy={false}
        >
            <Container xlWide className={style.container}>
                <Sale showOnlyOnMobile />
                {headline && (
                    <HeadingMedium>
                        <AnimatedText delay="0.4s">{headline}</AnimatedText>
                    </HeadingMedium>
                )}
                {teaser && (
                    <TaglineRegular as="h2" fontSize={[18, 26]}>
                        <AnimatedText delay="0.6s">{teaser}</AnimatedText>
                    </TaglineRegular>
                )}
                <CTA content={items} />
                <Platforms template={template} />
                {quotes && (
                    <Quotes
                        className={style.quotes}
                        content={quotes.contentCollection?.items}
                    />
                )}
                {items[2] && (
                    <Announcement
                        content={items[2]}
                        countDownContent={items[3]}
                    />
                )}
            </Container>
                
            <div className={style.video}>
                {!isMobile && (
                    <video
                        width="100%"
                        height="100%"
                        autoPlay
                        // loop
                        muted
                        // playsinline
                    >
                        <source
                            src="https://web.ccpgamescdn.com/aws/eveonline/videos/Revenant-WH-hevc-safari.mp4"
                            type="video/mp4;codecs=hvc1"
                        />
                        <source
                            src="https://web.ccpgamescdn.com/aws/eveonline/videos/Revenant-WH.webm"
                            type="video/webm"
                        />
                    </video>
                )}
            </div>
            <div className={style.bgImage}>
                <Visibility direction="isVisible" transitionDelay="1.5s">
                    <img src={`${image}?fm=webp`} alt="" />
                </Visibility>
            </div>
        </BackgroundImage>
    )

    return videoId ? (
        renderContent()
    ) : (
        <BackgroundImage
            url={imageFile?.url}
            size="cover"
            repeat="no-repeat"
            position="50% 50%"
            height={500}
        >
            {renderContent()}
        </BackgroundImage>
    )
}

export default Hero
